import React from 'react'

export default function Footer() {
    return (
        <div className='font-poppins p-7 2xl:p-10 md:flex justify-center gap-7 2xl:gap-10 bg-[#101010]  text-sm 2xl:text-base'>
            <div className='md:w-[60%]'>
                <p className='text-[#A1A1AA] font-bold'>© FABC LLC (First Academy of blockchain council)</p>
                <p className='text-[#71717A] mt-5'>FABC is dedicated to accelerating the adoption of blockchain, GenAI, and web3 as the emerging technology of choice in the digital revolution. Pioneering a new frontier where skill defines identity, free from human bias. We envision a thriving community rooted in Expertise-Based Recognition.</p>
                <div className='mt-5 flex gap-5 text-[#71717A] text-2xl'>
                    <a href="https://www.linkedin.com/company/fabc-llc/"  target="_blank"><i class="fa-brands fa-linkedin"> </i></a>
                    <a href="https://x.com/FabcGlobal" target="_blank" ><i class="fa-brands fa-x-twitter"></i></a>
                    <a href="https://www.instagram.com/fabc.global/" target="_blank" ><i class="fa-brands fa-instagram"></i></a>
                    <a href="https://medium.com/@FABCLLC/"  target="_blank"><i class="fa-brands fa-medium"></i></a>
                    <a href="https://www.facebook.com/share/kShGUtebskjqPtzP/?mibextid=qi2Omg"  target="_blank"><i class="fa-brands fa-facebook-f"></i></a>
                    <a href="https://t.me/FABCknowledgebase" target="_blank" ><i class="fa-brands fa-telegram"></i></a>
                    <a href="https://chat.whatsapp.com/GPS7J1QETwg3ocnjKofPT8"  target="_blank" ><i class="fa-brands fa-whatsapp"></i></a>
                </div>
                <div className='flex gap-5 mt-5 text-[#A1A1AA] font-bold'>
                    <p>Privacy Policy</p> <p>Terms of Use</p>
                </div>
            </div>
            <div className='md:w-[20%]'>
                <p className='text-[#A1A1AA] font-bold'>Dubai Presence</p>
                <p className='mt-5 text-[#71717A]'>First Academy of Blockchain Council FABC LLC Aspin tower 17 floor Sheikh Zayed Road DUBAI </p>
                <p className=' text-[#71717A]'>
                   <a href="tel:+971509718682"> +971 509718682 </a>
                    </p>
                <p className='mt-5 text-[#A1A1AA]'>Mail us:<span className='text-[#71717A] ml-3'><a href='mailto:support@fabc.global'>support@fabc.global </a></span></p>
            </div>
            <div className='md:w-[20%]'>
                <p className='text-[#A1A1AA] font-bold'>India Presence</p>
                <p className='mt-5 text-[#71717A]'>First Academy of Blockchain Council No: 51, Ground Floor, Tower A Tek Meadows, OMR Sholinganallur, Chennai</p>
                <p className=' text-[#71717A]'>
                 <a href='tel:+919566299429' >  +91 9566299429</a> 
                    </p>
            </div>
        </div>
    )
}